import React, {Component} from "react";
import {
    Button,
    Card,
    Checkbox,
    FormLayout, Frame,
    Heading,
    IndexTable,
    Layout, Loading,
    SkeletonBodyText,
    SkeletonDisplayText,
    SkeletonPage, Spinner,
    TextContainer,
    TextStyle,
    Toast, Tooltip
} from "@shopify/polaris";
import {ResourcePicker} from "@shopify/app-bridge-react";
import "../custom.css";
import moment from "moment/moment";


export class ProductList extends Component {
    
    constructor(props) {
        super(props);
        this.request = props.fetchFunction;
        this.state = {
            loading: true, 
            products: [], 
            open: false, 
            savedProducts: [], 
            loadingButtons: [], 
            services: [],
            orders: [],
            showOrderView: false,
            showHooksView: false,
            hooksStatus: {addesses: false, topics: false, hooks_not_installed: false},
            d1: moment().subtract(3, "days").format("YYYY-MM-DD"),
            d2: moment().add(1, "days").format("YYYY-MM-DD"),
            toastActive: false,
            errorToastActive: false,
            errorToastContent: "",
        };
    }
    
    componentDidMount() {
        this.getSavedProducts();
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.showHooksView === false && this.state.showHooksView === true) {
            this.checkHooks();
        }
    }

    getSavedProducts()
    {
        this.request("/products/list").then(response => {
            let fetched = [];
            let services = [(<option key={"option-" + 0} value={0} label={"Ei liitetty palveluun"}></option>)];
            if(response.ok) {
                response.json().then(result => {
                    fetched = result.products;
                    let tempServices = result.services.map(s => {return (<option key={"option-" + s.id} label={s.name} value={s.id}></option>)});
                    services = services.concat(tempServices);
                }, (err) => console.log(err))
                    .finally(() => {
                        console.log("finale");
                        this.setState({loading: false, services: services, savedProducts: fetched, products: []} );
                    });
            } else {
                console.log("err");
                let errorMsg = "Virhe tapahtui pyyntöä käsitellessä";
                if(response.status === 401) {
                    errorMsg = "Tunnistautuminen epäonnistui";
                }
                this.setState({loading: false, errorToastContent: errorMsg, errorToastActive: true});
            }
        }).catch(err => {
            console.log(err)
            this.setState({loading: false, errorToastContent: "Virhe tapahtui pyyntöä käsitellessä", errorToastActive: false});
        })
    }
    
    getSavedOrders() {
        this.setState({loading: true});
        
        this.request("/orders/list/", {method: "POST", body: JSON.stringify({date1: this.state.d1, date2: this.state.d2})}).then(response => {
           if(response.ok) {
               response.json()
                   .then(result => this.setState({orders: result.orders, showOrderView: true, loading: false}))
                   .catch(err => console.log(err))
                   .finally(() => this.setState({loading: false}))
           } else {
               let errorMsg = "Virhe tapahtui pyyntöä käsitellessä";
               if(response.status === 401) {
                   errorMsg = "Tunnistautuminen epäonnistui";
               }
               this.setState({loading: false, errorToastContent: errorMsg, errorToastActive: true});
           }
        }, (err) => {
            console.log(err);
            this.setState({loading: false, errorToastContent: "Virhe tapahtui pyyntöä käsitellessä", errorToastActive: false})
        });
    }
    
    getHookList() {
        this.setState({showHooksView: true});
    }
    
    saveProductGiftCardInfo() {
        this.setState({loading: true});
        let prods = this.state.products.map(p => {
           p.price = p.price.replace(",", "."); 
           return p;
        });
        this.request("/products/update", {method: "POST", body: JSON.stringify(prods)})
            .then(response => {
                if (!response.ok) {
                    let errorMsg = "Virhe tapahtui pyyntöä käsitellessä";
                    if(response.status === 401) {
                        errorMsg = "Tunnistautuminen epäonnistui";
                    }
                    this.setState({loading: false, errorToastContent: errorMsg, errorToastActive: true});
                }
            })
            .catch(err => {
                let errorMsg = "Virhe tapahtui pyyntöä käsitellessä";
                this.setState({loading: false, errorToastContent: errorMsg, errorToastActive: true});
                console.log(err)
            })
            .finally(() => {
                this.getSavedProducts();
            });
    }

    updateProductGiftCardInfo() {
        this.setState({loading: true});
        let prods = this.state.savedProducts;
        this.request("/products/update", {method: "POST", body: JSON.stringify(prods)})
            .then(response => {
                if (!response.ok) {
                    let errorMsg = "Virhe tapahtui pyyntöä käsitellessä";
                    if(response.status === 401) {
                        errorMsg = "Tunnistautuminen epäonnistui";
                    }
                    this.setState({loading: false, errorToastContent: errorMsg, errorToastActive: true});
                }
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                this.getSavedProducts();
            });
    }
    
    deleteSavedGiftCard(product_id) {
        let loadingButtons = this.state.loadingButtons;
        loadingButtons.push(product_id);
        this.setState({loadingButtons: loadingButtons});
        this.request("/products/delete/"+ product_id, {method: "DELETE"})
            .then((response) => {
                let alteredsavedProducts = this.state.savedProducts;
                if(response.ok) {
                    alteredsavedProducts = this.state.savedProducts
                        .filter(e => e.product_id !== product_id)
                        .map(p => p);
                }
                this.setState({savedProducts: alteredsavedProducts, loadingButtons: []});
            } )
            .catch((err) => {
                console.log("error")
                console.log(err)
                this.setState({loadingButtons: []})
            });
    }
    
    sendGiftCard(o)
    {
        this.state.loadingButtons.push(o.id);
        this.setState({loadingButtons: this.state.loadingButtons});
        this.request("/orders/giftcard-actions", {method: "POST", body: JSON.stringify(o)})
            .then(response => {
                let lButtons = this.state.loadingButtons.filter(l => {
                    return l !== o.id;
                });
                if(response.ok)
                {
                    this.setState({toastActive: true, loadingButtons: lButtons})            
                    return;
                }
                let errorMsg = "Virhe tapahtui pyyntöä käsitellessä";
                if(response.status === 401) {
                    errorMsg = "Tunnistautuminen epäonnistui";
                }
                this.setState({loadingButtons: lButtons, errorToastContent: errorMsg, errorToastActive: true})
            }, err => {
                let lButtons = this.state.loadingButtons.filter(l => {
                    return l !== o.id;
                });
                let errorMsg = "Virhe tapahtui pyyntöä käsitellessä";
                this.setState({loadingButtons: lButtons, errorToastContent: errorMsg, errorToastActive: true});
            })
    }
    
    createGiftCardForDownload(o)
    {
        this.state.loadingButtons.push(o.id);
        if(o.downloadLink) {
            o.downloadLink = null;
        }
        this.setState({loadingButtons: this.state.loadingButtons});
        this.request("/orders/giftcard-download-link", {method: "POST", body: JSON.stringify(o)})
            .then(response => {
                var errorToastContent = "Virhe tapahtui pyyntöä käsitellessä";
                let lButtons = this.state.loadingButtons.filter(lb => {
                    return lb !== o.id;
                });
                if(response.ok) {
                    response.json().then(result => o["downloadLink"] = result.url)
                        .finally(() => this.setState({toastActive: true, loadingButtons: lButtons}))
                } else {
                    this.setState({errorToastActive: true, errorToastContent: errorToastContent, loadingButtons: lButtons});
                }
            }, function(err) {
                var errorToastContent = "Virhe tapahtui pyyntöä käsitellessä";
                let lButtons = this.state.loadingButtons.filter(lb => {
                    return lb !== o.id;
                });
                
                this.setState({errorToastActive: true, errorToastContent: errorToastContent, loadingButtons: lButtons});
                console.log(err);
            })
    }
    
    checkHooks() {
        this.setState({loading: true});
        this.request("/hooks/list-hooks", {method: "POST"}).then(response => {
            this.setState({loading: false});
            if(response.ok) {
                response.json().then((result) => this.setState({hooksStatus: {addresses: result.addresses_match, topics: result.topics_match, hooks_not_installed: result.hooks_not_installed}}))
            } else {
                var errorToastContent = "Virhe tapahtui pyyntöä käsitellessä"
                if(response.status === 401) {
                    errorToastContent = "Tunnistautuminen epäonnistui";
                }
                this.setState({hooksStatus: {addresses: false, topics: false}, errorToastContent: errorToastContent, errorToastActive: true});
            }
        }, err => {
            console.log(err);
            var errorToastContent = "Virhe tapahtui pyyntöä käsitellessä"
            this.setState({loading: false, hooksStatus: {addresses: false, topics: false},  errorToastContent: errorToastContent, errorToastActive: true})
        })
    }

    updateHooks() {
        this.setState({loading: true});
        this.request("/hooks/update", {method: "POST"}).then(response => {
            this.setState({loading: false});
            if(response.ok) {
                this.checkHooks();
            } else {
                var errorToastContent = "Virhe tapahtui pyyntöä käsitellessä"
                if(response.status === 401) {
                    errorToastContent = "Tunnistautuminen epäonnistui";
                }
                this.setState({hooksStatus: {addresses: false, topics: false}, errorToastContent: errorToastContent, errorToastActive: true});
            }
        }, err => {
            console.log(err);
            var errorToastContent = "Virhe tapahtui pyyntöä käsitellessä"
            this.setState({hooksStatus: {addresses: false, topics: false}, errorToastContent: errorToastContent, errorToastActive: true});
        })
    }
    
    render() {

        const errorToastMarkup = this.state.errorToastActive ? (<Toast onDismiss={() => this.setState({errorToastActive: false})} content={this.state.errorToastContent}></Toast>) : null;

        const handleSelection = resources => {
            let list = resources.selection;
            this.setState({open: false, products: list.map(product => {
                let price = 0;
                if(product.variants && product.variants.length) price = product.variants[0].price;
                let product_id = product.id.replace("gid://shopify/Product/", "");
                let endDate = new Date();
                endDate = moment().format("YYYY-MM-DD");
                return {
                    product_id: product_id,
                    name: product.title,
                    price: price,
                    begins: endDate,
                    ends: endDate,
                    months: 0,
                    isAmountCard: false,
                    usageAmount: 1,
                    service: 0,
                    count: 0,
                }
            })});
        };
        
        
        if(this.state.loading) {
            return (<SkeletonPage primaryAction>
                <Layout>
                    <Layout.Section>
                        <Card sectioned>
                            <SkeletonBodyText />
                        </Card>
                        <Card sectioned>
                            <TextContainer>
                                <SkeletonDisplayText size={"small"}></SkeletonDisplayText>
                            </TextContainer>
                        </Card>
                    </Layout.Section>
                </Layout>
            </SkeletonPage>)
        }
        
        const resourcePicker = <ResourcePicker // Resource picker component
            resourceType="Product"
            showVariants={false}
            open={this.state.open}
            onSelection={handleSelection}
            onCancel={() => this.setState({open: false})}
        />;
        
        if(this.state.showHooksView) {
            return (<Layout>
                <Card primaryFooterAction={{content: "Takaisin", onAction: () => this.setState({showHooksView: false})}}>
                    <Card.Section>
                        <div>Webhook-tilausten tila: </div>
                        {this.state.hooksStatus.addresses === true && this.state.hooksStatus.topics ? (<div style={{color: "green"}}>OK</div>) : (<div>
                            <div style={{color: "red"}}>Puutteita</div>
                            {this.state.hooksStatus.addresses === false ? <div>Vanhentunut osoite, tarkista tai tilaa webhookit uudelleen</div> : <div></div>}
                            {this.state.hooksStatus.topics === false ? <div>Kaikkia webhookeja ei löydetty, tarkista tai tilaa webhookit uudelleen</div> : <div></div>}
                        </div>)}
                    </Card.Section>
                    <Card.Section>
                        <Button onClick={() => this.checkHooks()}>Tarkista</Button>
                        <Button onClick={() => this.updateHooks()}>Tilaa webhookit uudelleen</Button>
                    </Card.Section>
                    <Card.Section>
                    </Card.Section>
                </Card>
            </Layout>);
        }
        
        if(this.state.showOrderView) {
            if(this.state.orders.length === 0) {
                return (<div className={"giftcards-container"}>
                    <Layout>
                        <Card primaryFooterAction={{content: "Takaisin" ,onAction: () => this.setState({showOrderView: false})}}>
                            <Card.Section>
                                <div>Hae tilauksia ajalta:</div>
                                <FormLayout.Group>
                                    <div>
                                        <label>Haun alku</label><br />
                                        <input type={"date"} onChange={newDate => this.setState({d1: newDate.currentTarget.value})} value={this.state.d1}/>
                                    </div>
                                    <div>
                                        <label>Haun loppu</label><br />
                                        <input type={"date"} onChange={newDate => this.setState({d2: newDate.currentTarget.value})} value={this.state.d2}/>
                                    </div>
                                    <div>
                                        <Button onClick={() => this.getSavedOrders()}>Hae</Button>
                                    </div>
                                </FormLayout.Group>
                            </Card.Section>
                            <Card.Section>
                                <TextStyle variation={"strong"} style={{textAlign: "center"}}>Lahjakorttituotetilauksia ei löytynyt.</TextStyle>
                            </Card.Section>
                        </Card>
                    </Layout>
                </div>)
            }
            
            const parseDate = (dateString) => {
                if(!dateString) return "Päivämäärää ei voitu lukea";
                return moment(dateString).format("DD.MM.YYYY");
            }
            const toastMarkup = this.state.toastActive ? (
                <Toast content="Toiminto suoritettu onnistuneesti." onDismiss={() => this.setState({toastActive: !this.state.toastActive})} />
            ) : null;
            const rowMarkup = this.state.orders.map((o, index) => (
                    <IndexTable.Row
                        id={o.id}
                        key={o.id}
                        position={index}
                    >
                        <IndexTable.Cell>
                            <TextStyle variation="strong">{o.order_id}</TextStyle>
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                            <TextStyle>{o.product_name}</TextStyle>
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                            <TextStyle>{o.shortcode}</TextStyle>
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                            <TextStyle>{o.email_sent ? "Kyllä" : "Ei"}</TextStyle>
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                            <TextStyle>{parseDate(o.created_at)}</TextStyle>
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                            <div style={this.state.loadingButtons.includes(o.id) ? {display: "block"} : {display: "none"}}>
                                <Spinner accessibilityLabel="Ladataan..." size="large" />
                            </div>
                            <div style={this.state.loadingButtons.includes(o.id) ? {display: "none"} : {display: "block"}}>
                                <div>
                                    <Button id={o.id + "button"} loading={this.state.loadingButtons.includes(o.id)} onClick={() => this.sendGiftCard(o)}>
                                        {o.shortcode.length === 0 ? "Luo lahjakortin koodi" : "Lähetä uudelleen"}
                                    </Button>
                                </div>
                                <div style={o.shortcode.length === 0 ? {display: "none"} : {}}>
                                    <Button id={o.id + "button2"} loading={this.state.loadingButtons.includes(o.id)} onClick={() => this.createGiftCardForDownload(o)}>
                                        {"Hae latauslinkki"}
                                    </Button>
                                </div>
                                <div style={o.downloadLink ? {display: "block"} : {display: "none"}}>
                                    <hr />
                                    <a href={o.downloadLink} target={"_blank"} rel={"noreferrer"}>Lataa lahjakortti {o.shortcode}</a>
                                </div>
                            </div>
                        </IndexTable.Cell>
                    </IndexTable.Row>
                )
            );
            return (<Frame>
                <Card>
                    <Card.Section>
                        <div>Hae tilauksia ajalta:</div>
                        <FormLayout.Group>
                            <div>
                                <label>Haun alku</label><br />
                                <input type={"date"} onChange={newDate => this.setState({d1: newDate.currentTarget.value})} value={this.state.d1}/>
                            </div>
                            <div>
                                <label>Haun loppu</label><br />
                                <input type={"date"} onChange={newDate => this.setState({d2: newDate.currentTarget.value})} value={this.state.d2}/>
                            </div>
                            <div>
                                <Button onClick={() => this.getSavedOrders()}>Hae</Button>
                            </div>
                        </FormLayout.Group>
                    </Card.Section>
                    <Card.Section actions={
                        [
                            {content: "Peruuta", onAction: () => this.setState({showOrderView: false, orders: []})},
                        ]} title="Lahjakorttitilaukset">
                        <IndexTable headings={
                            [
                                {title: "Tilaus"},
                                {title: "Tuote"},
                                {title: "Lahjakortin koodi"},
                                {title: "Lähetetty"},
                                {title: "Tilaus tehty"},
                                {title: "Toiminnot"}
                            ]} itemCount={this.state.orders.length}
                                    resourceName={{singular: "Tilaus", plural: "Tilaukset"}}
                                    selectable={false}>
                            {rowMarkup}
                        </IndexTable>
                        {toastMarkup}
                        {errorToastMarkup}
                    </Card.Section>
                </Card>
            </Frame>)
        }
        
        if(this.state.products.length > 0) {

            const handleNormalInputChange = (newValue, ind, key) => {
                const newProductsList = this.state.products.map((obj, index) => {
                    if(index === ind) {
                        return {
                            ...obj,
                            [key]: newValue.currentTarget.value
                        }
                    }
                    return obj;
                });
                this.setState({products: newProductsList});
            }

            const handleInputChange = (newValue, ind, key) => {
                const newProductsList = this.state.products.map((obj, index) => {
                    if(index === ind) {
                        return {
                            ...obj,
                            [key]: newValue
                        }
                    }
                    return obj;
                });
                this.setState({products: newProductsList});
            }
            
            const rowMarkup = this.state.products.map(
                ({product_id, name, price, begins, ends, months, isAmountCard, usageAmount, service}, index) => {
                    return (
                        <FormLayout key={"parent1" + product_id} className={"giftcard-input-group"}>
                            <Heading key={'item1' + product_id}>{name}</Heading>
                            <Checkbox key={'item2' + product_id} label={"Arvokortti"} autoComplete={"false"} onChange={newValue => handleInputChange(newValue, index, 'isAmountCard')} checked={isAmountCard} />
                            <FormLayout.Group key={"parent2" + product_id}>
                                <div key={"div1-" + product_id}>
                                    <label key={"label2-" + product_id}>Voimassaolo alkaa</label><br key={"br2" + product_id} />
                                    <input type={"date"} key={'item4' + product_id} autoComplete={"false"} onChange={newValue => handleNormalInputChange(newValue, index, 'begins')} value={begins} />
                                </div>
                                <div key={"div2-" + product_id}>
                                    <label key={"label3-" + product_id} >Voimassaolo päättyy</label><br key={"br3" + product_id} />
                                    <input type={"date"} key={'item5' + product_id} autoComplete={"false"} onChange={newValue => handleNormalInputChange(newValue, index, 'ends')} value={ends} />
                                </div>
                                <div style={{display: "none"}}>
                                    <label key={"label1-" + product_id}>Arvo</label><br key={"br1" + product_id} />
                                    <div>{price} (vanhoja tilauksia varten)</div>
                                </div>
                                <div>
                                    <label key={"label4-" + product_id}>Voimassaolo kuukausina</label><br key={"br4" + product_id} />
                                    <input type={"text"} key={'item6' + product_id} autoComplete={"false"} onChange={newValue => handleNormalInputChange(newValue, index, 'months')} value={months} />
                                </div>
                                <div>
                                    <label key={"label5-" + product_id}>Käyttökerrat</label><br key={"br5" + product_id} />
                                    <input type={"text"} key={'item7' + product_id} autoComplete={"false"} onChange={newValue => handleNormalInputChange(newValue, index, 'usageAmount')} value={usageAmount} />
                                </div>                                
                                <div className={""} key={"div3-" + product_id}>
                                    <label key={"label6-" + product_id}>Palvelu</label><br key={"br6-" + product_id}/>
                                    <select style={{backgroundColor: "#fff", width: "100%"}} value={service} key={"select1-" + product_id} onChange={newValue => handleNormalInputChange(newValue, index, "service")}>
                                        {this.state.services}
                                    </select>
                                </div>
                            </FormLayout.Group>
                        </FormLayout>
                    )
            });
            
            return (
                <Layout>
                <Card
                    secondaryFooterActions={[{content: "Peruuta", onAction: () => this.setState({products: []})}]}
                    primaryFooterAction={{content: "Tallenna", onAction: () => this.saveProductGiftCardInfo()}}>
                    {resourcePicker}
                    <Card.Section title="Lisää lahjakorttituotteita">
                        {rowMarkup}
                    </Card.Section>
                </Card>
                <Frame>
                    {errorToastMarkup}
                </Frame>
                </Layout>
            );
        }
        
        if(this.state.savedProducts.length > 0) {

            const handleNormalInputChange = (newValue, ind, key) => {
                const newProductsList = this.state.savedProducts.map((obj, index) => {
                    if(index === ind) {
                        return {
                            ...obj,
                            [key]: newValue.currentTarget.value
                        }
                    }
                    return obj;
                });
                this.setState({savedProducts: newProductsList});
            }

            const handleInputChange = (newValue, ind, key) => {
                const newProductsList = this.state.savedProducts.map((obj, index) => {
                    if(index === ind) {
                        return {
                            ...obj,
                            [key]: newValue
                        }
                    }
                    return obj;
                });
                this.setState({savedProducts: newProductsList});
            }
            
            const savedRows = this.state.savedProducts.map(
                ({product_id, name, price, begins, ends, months, isAmountCard, usageAmount, service}, index) => {
                    return (
                        <FormLayout key={"parent1" + product_id} className={"giftcard-input-group"}>
                            <Heading key={'item1' + product_id}>{name}</Heading>
                            <Checkbox key={'item2' + product_id} label={"Arvokortti"} autoComplete={"false"} onChange={newValue => handleInputChange(newValue, index, 'isAmountCard')} checked={isAmountCard} />
                            <FormLayout.Group key={"parent2" + product_id}>
                                <div key={"div1-" + product_id}>
                                    <label key={"label2-" + product_id}>Voimassaolo alkaa</label><br key={"br2" + product_id} />
                                    <input type={"date"} key={'item4' + product_id} autoComplete={"false"} onChange={newValue => handleNormalInputChange(newValue, index, 'begins')} value={begins} />
                                </div>
                                <div key={"div2-" + product_id}>
                                    <label key={"label3-" + product_id} >Voimassaolo päättyy</label><br key={"br3" + product_id} />
                                    <input type={"date"} key={'item5' + product_id} autoComplete={"false"} onChange={newValue => handleNormalInputChange(newValue, index, 'ends')} value={ends} />
                                </div>
                                <div style={{display: "none"}}>
                                    <label key={"label1-" + product_id}>Arvo</label><br key={"br1" + product_id} />
                                    <div>{price} (vanhoja tilauksia varten)</div>
                                </div>
                                <div>
                                    <label key={"label4-" + product_id}>Voimassaolo kuukausina</label><br key={"br4" + product_id} />
                                    <input type={"text"} key={'item6' + product_id} autoComplete={"false"} onChange={newValue => handleNormalInputChange(newValue, index, 'months')} value={months} />
                                </div>
                                <div>
                                    <label key={"label5-" + product_id}>Käyttökerrat</label><br key={"br5" + product_id} />
                                    <input type={"text"} key={'item7' + product_id} autoComplete={"false"} onChange={newValue => handleNormalInputChange(newValue, index, 'usageAmount')} value={usageAmount} />
                                </div>
                                <div className={""} key={"div3-" + product_id}>
                                    <label key={"label6-" + product_id}>Palvelu</label><br key={"br6-" + product_id}/>
                                    <select style={{backgroundColor: "#fff", width: "100%"}} value={service} key={"select1-" + product_id} onChange={newValue => handleNormalInputChange(newValue, index, "service")}>
                                        {this.state.services}
                                    </select>
                                </div>
                                <Button key={"item9" + product_id} id={"delete-button"} loading={this.state.loadingButtons.includes(product_id)} onClick={() => this.deleteSavedGiftCard(product_id)}>Poista lahjakorttitiedot</Button>
                            </FormLayout.Group>
                        </FormLayout>
                    )
                });
            
            return <Layout>
                <Card>
                    {resourcePicker}
                    <Card.Section actions={
                        [
                            {content: "Lisää tuotteita", onAction: () => this.setState({open: true})},
                            {content: "Tilaukset", onAction: () => this.getSavedOrders()},
                        ]} title="Lahjakorttituotteet">
                        <TextContainer>
                            <p>Lisää lahjakorttituotteita yllä olevasta linkistä.</p>
                        </TextContainer>
                    </Card.Section>
                    <Card.Section>
                        <div className={"webhookit"}>
                            <Button onClick={() => this.getHookList()}>Tarkista Webhookit</Button>
                        </div>
                    </Card.Section>
                    <Card.Section actions={[{content: "Tallenna", onAction:() => this.updateProductGiftCardInfo()}]} title={"Tallennetut tuotteet"}>
                        {savedRows}
                    </Card.Section>
                </Card>
                <Frame>
                    {errorToastMarkup}
                </Frame>
            </Layout>
        }
        
        return (
            <Layout>
                <Card
                    primaryFooterAction={{content: "Lisää tuotteita", onAction: () => this.setState({open: true})}}>
                    {resourcePicker}
                    <Card.Section>
                        <TextContainer>
                            <Heading>Lahjakorttituotteet</Heading>
                        </TextContainer>
                    </Card.Section>
                    <Card.Section title="">
                        <TextContainer>
                            <p>Lisää lahjakorttituotteita alla olevasta painikkeesta.</p>
                        </TextContainer>
                    </Card.Section>
                    <Card.Section title={"Tallennetut tuotteet"}>
                        <p>Ei tuotteita</p>
                    </Card.Section>
                </Card>
                <Frame>
                    {errorToastMarkup}
                </Frame>
            </Layout>
        )
    }

}