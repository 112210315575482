import React from 'react';
import {Page} from "@shopify/polaris";
import {ProductList} from "./ProductList";

export function EmptyStatePage(actions)
{
    return (
        <Page>
            <ProductList fetchFunction={actions.fetchFunction} />
        </Page>
    );
}
