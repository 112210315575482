import {Provider as AppBridgeProvider, useAppBridge,} from "@shopify/app-bridge-react";
import {authenticatedFetch} from "@shopify/app-bridge-utils";
import {AppProvider as PolarisProvider} from "@shopify/polaris";
import translations from "@shopify/polaris/locales/en.json";
import "@shopify/polaris/build/esm/styles.css";
import {EmptyStatePage} from "./components/Home";
import {TitleBar} from "@shopify/app-bridge/actions";
import deepMerge from "@shopify/app-bridge/actions/merge";
import {Component} from "react";

export class App extends Component {

    constructor(props) {
        super(props);
        this.state = {show: false, error: false};
        this.stateHandler = this.stateHandler.bind(this);
    }

    stateHandler(state) {
        this.setState(state);
    }

    render() {
        if (window.top === window.self)
            return (<LoadingProvider></LoadingProvider>)
        else {
            return (
                <PolarisProvider i18n={translations}>
                    <AppBridgeProvider
                        config={{
                            apiKey: process.env.REACT_APP_SHOPIFY_API_KEY,
                            host: new URLSearchParams(window.location.search).get('host'),
                            debug: true,
                        }}
                    >
                        <MyProvider></MyProvider>
                    </AppBridgeProvider>
                </PolarisProvider>
            );
        }
    }
}

const request = (url = '', options = {}) => {
        const aggregatedOptions = deepMerge(options, {
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'error', // manual, *follow, error
            referrerPolicy: 'origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        })
    return fetch(url, aggregatedOptions); // parses JSON response into native JavaScript objects
}

function MyProvider() {
    const app = useAppBridge();
    
    const titleBarOptions = {
        title: "Ajas Lahjakortit",
    }
    const fetchFunction = authenticatedFetch(app, request);
    TitleBar.create(app, titleBarOptions);
    
    return <EmptyStatePage fetchFunction={fetchFunction} />;
}

function LoadingPage(props) {
    window.location.assign("/shopifyauth" + window.location.search);
    return 'Redirecting to auth...';
}

function LoadingProvider(props) {
    
    return <LoadingPage />;
}